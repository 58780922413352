<template>
  <div>
    <v-container>
      <!-- Buttons -->
      <v-row>
        <dashboard-btn target="Statistics" icon="postbox" cols="3" v-if="user.accessRoles.statistics.memorialMail">Gedenk-Post</dashboard-btn>
        <dashboard-btn target="StatisticsMemorialPageOverview" icon="gsuser" cols="3" v-if="user.accessRoles.statistics.memorialPage">Gedenkseiten (User)</dashboard-btn>
        <dashboard-btn target="Statistics" icon="candle" cols="3" v-if="user.accessRoles.statistics.candle">Kerzen</dashboard-btn>
        <dashboard-btn target="Statistics" icon="news" cols="3" v-if="user.accessRoles.statistics.newsletter">Newsletter</dashboard-btn>
        <dashboard-btn target="StatisticsCrawlers" icon="pacman" cols="3" v-if="user.accessRoles.statistics.crawler">Crawler</dashboard-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DashboardBtn from '@/components/dashboard/DashboardBtn.vue'

export default {
  components: {
    dashboardBtn: DashboardBtn
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  }
}
</script>
